import React from 'react';
import { Link } from 'react-router-dom';
import useDocumentTitle from '../Data/useDocumentTitle';
import useResource from '../Data/useResource';
import useQuery from '../Data/useQuery';
import DataTable from './DataTable';
import ErrorMessage from './ErrorMessage';

function SearchResults({ query }) {

    const [accounts, error] = useResource(
        `/admin/accounts/search?query=${encodeURIComponent(query)}`
    );

    return (
        <>
            <ErrorMessage error={error} />
            {accounts && (
                <>
                    <h3>Search Results</h3>
                    {accounts.map(account => (
                        <>
                            <DataTable object={account} header="Account" />
                            <div className="actions">
                                <Link className="button" to={`/accounts/${account.id}`}>View Account</Link>
                            </div>
                        </>
                    ))}
                </>
            )}
        </>
    );
}

export default function Accounts() {
    useDocumentTitle('Accounts');
    const [query] = useQuery('query');
    return (
        <>
            <h2>Accounts</h2>
            <form>
                <input type="text" placeholder="ID or Email" name="query" defaultValue={query} />
                <input type="submit" value="Search" />
            </form>
            {query && <SearchResults query={query} />}
        </>
    );
};
