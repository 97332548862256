import { token } from './useAuthentication';

export default class API {

    static async request(path, options = {}) {
        const url = process.env.REACT_APP_API_URL + path;
        options.headers = {
            'Authorization': `Bearer: ${token()}`
        };
        const response = await window.fetch(url, options);
        switch (response.status) {
            case 200:
                return response.json();
            case 204:
                return;
            case 400:
            case 422:
                const fields = async () => {
                    try {
                        return await response.json();
                    }
                    catch {
                        return {};
                    }
                };
                throw new APIValidationError(response, await fields());
            default:
                throw new APIError(response);
        }
    }
};

export class APIError extends Error {

    constructor(response) {
        const message = APIError.message(response);
        super(message);
    }

    static message(response) {
        switch (response.status) {
            case 400:
                return 'Bad Request';
            case 401:
                return 'Unauthenticated';
            case 403:
                return 'Unauthorized';
            case 404:
                return 'Not Found';
            case 422:
                return 'Unprocessable Entity';
            default:
                return response.statusText;
        }
    }
};

export class APIValidationError extends APIError {

    constructor(response, fields) {
        super(response);
        this.fields = fields;
    }
};
