import React from 'react';
import { createBrowserRouter, useParams, RouterProvider } from "react-router-dom";
import useAuthentication from '../Data/useAuthentication';
import Account from './Account';
import Accounts from './Accounts';
import Home from './Home';
import Login from './Login';
import MyAccount from './MyAccount';

function ValidateAccount() {
    const params = useParams();
    if (params.account_id.match(/\d+/) === null) {
        return null;
    }
    return <Account />;
}

const router = createBrowserRouter([
  {
    path: "/accounts/:account_id",
    element: <ValidateAccount />,
  },
  {
    path: "/accounts",
    element: <Accounts />,
  },
  {
    path: "/account",
    element: <MyAccount />,
  },
  {
    path: "/",
    element: <Home />,
  },
]);

export default function Root() {
    const isAuthenticated = useAuthentication();
    return isAuthenticated
        ? <RouterProvider router={router} />
        : <Login />;
};
