import { useEffect } from 'react';

export default function useEventListener(type, listener) {
    useEffect(() => {
        window.addEventListener(type, listener);
        return () => {
            window.removeEventListener(type, listener);
        };
    }, [type, listener]);
};
