import { useLocalStorage } from './useStorage';

const key = 'authentication-token';

function isValid(token) {
    return (typeof token === 'string' && token.length > 0);
}

export default function useAuthentication() {
    const [token] = useLocalStorage(key);
    return isValid(token);
};

export function token() {
    const token = window.localStorage.getJSON(key);
    if (isValid(token)) {
        return token;
    }
}

export function login(token) {
    if (isValid(token)) {
        window.localStorage.setJSON(key, token);
    }
}

export function logout() {
    window.localStorage.setJSON(key, undefined);
}
