import React, { useCallback } from 'react';
import useForm from '../Data/useForm';
import ErrorMessage from './ErrorMessage';

export default function MyAccount() {
    return (
        <div id="my-account">
            <h2>My Account</h2>
            <UpdateEmailForm />
            <UpdatePasswordForm />
        </div>
    );
};

function UpdateEmailForm() {

    const [onSubmit, error, isLoading] = useForm(
        'PATCH',
        '/account/email',
        useCallback(() => {
            window.alert('An email has been sent to you with a link to confirm your email address.');
            window.location.reload();
        }, []),
        true
    );

    return (
        <form onSubmit={onSubmit}>
            <ErrorMessage error={error} />
            <input type="password" name="account[email]" placeholder="New Email" />
            <input type="password" name="password" placeholder="Current Password" />
            <input disabled={isLoading} type="submit" value="Update Email" />
        </form>
    );
}

function UpdatePasswordForm() {

    const [onSubmit, error, isLoading] = useForm(
        'PATCH',
        '/account/password',
        useCallback(() => {
            window.location.reload();
        }, []),
        true
    );

    const _onSubmit = (event) => {
        const password = event.target['account[password]'].value;
        const password_confirmation = event.target['account[password_confirmation]'].value;
        if (password === password_confirmation) {
            onSubmit(event);
        }
        else {
            event.preventDefault();
            alert('Passwords do not match.');
        }
    };

    return (
        <form onSubmit={_onSubmit}>
            <ErrorMessage error={error} />
            <input type="password" name="account[password]" placeholder="New Password" />
            <input type="password" name="account[password_confirmation]" placeholder="Confirm New Password" />
            <input type="password" name="password" placeholder="Current Password" />
            <input disabled={isLoading} type="submit" value="Update Password" />
        </form>
    );
}
