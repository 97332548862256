import { APIValidationError } from '../Data/API';

export default class Formatter {

    static key(key) {
        return key.replace(/_/g, ' ');
    }

    static value(value) {
        if (typeof value === 'string') {
            const delta = Date.parse(value);
            if (typeof delta === 'number' && Number.isNaN(delta) === false) {
                const date = new Date(delta);
                return date.toUTCString();
            }
        }
        return value;
    }

    static errorAlert(error) {
        let message = error.toString();
        if (error instanceof APIValidationError) {
            for (const [name, value] of Object.entries(error.fields)) {
                message += `\n${name}: ${value.map(({ error }) => error).join(', ')}`;
            }
        }
        return message;
    }
}
