import React from 'react';
import Formatter from '../Data/Formatter';

export default function DataTable({ object, header }) {
    return (
        <table className="data">
            {header && (
                <thead>
                    <tr>
                        <td colSpan="2">{header}</td>
                    </tr>
                </thead>
            )}
            <tbody>
                {Object.entries(object).map(([key, value]) => (
                    <tr key={key}>
                        <td>{Formatter.key(key)}</td>
                        <td>{Formatter.value(value) ?? <>&nbsp;</>}</td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
};
