import React, { useCallback, useState } from 'react';
import { useParams } from 'react-router';
import useForm from '../Data/useForm';
import useResource from '../Data/useResource';
import DataTable from './DataTable';
import ErrorMessage from './ErrorMessage';

export default function Account() {

    const { account_id } = useParams();
    const [account, error] = useResource(`/admin/accounts/${account_id}`);

    return (
        <div id="account">
            <h2>Account</h2>
            <ErrorMessage error={error} />
            {account && (
                <>
                    <DataTable object={account} header="Account" />
                    <div className="actions">
                        <SendConfirmEmailToken account={account} />
                        <SendResetPasswordToken account={account} />
                    </div>
                    <Subscriptions />
                </>
            )}
        </div>
    );
}

function SendConfirmEmailToken({ account }) {

    const [onSubmit, error, isLoading] = useForm(
        'POST',
        `/admin/accounts/${account.id}/email/confirm`,
        useCallback(() => {
            alert('Sent!');
        }, []),
        true
    );

    return (
        <form className="button" onSubmit={onSubmit}>
            <ErrorMessage error={error} />
            <input disabled={isLoading} type="submit" value="Send Confirm Email Token" />
        </form>
    );
}

function SendResetPasswordToken({ account }) {

    const [onSubmit, error, isLoading] = useForm(
        'POST',
        `/admin/accounts/${account.id}/password/reset`,
        useCallback(() => {
            alert('Sent!');
        }, []),
        true
    );

    return (
        <form className="button" onSubmit={onSubmit}>
            <ErrorMessage error={error} />
            <input disabled={isLoading} type="submit" value="Send Reset Password Token" />
        </form>
    );
}

function Subscriptions() {

    const { account_id } = useParams();
    const [subscriptions, error] = useResource(`/admin/accounts/${account_id}/subscriptions`);

    return (
        <>
            <h3>Subscriptions</h3>
            <AddFreeSubscription />
            <ErrorMessage error={error} />
            {subscriptions && (
                subscriptions.map(subscription => (
                    <Subscription key={subscription.id} subscription={subscription} />
                ))
            )}
        </>
    );
}

function Subscription({ subscription }) {

    const [showForm, setShowForm] = useState(false);

    const Button = ({ showForm, children }) => {
        return (
            <button
                onClick={(event) => {
                    event.preventDefault();
                    setShowForm(showForm);
                }}
            >{children}</button>
        );
    };

    return (
        <>
            <DataTable object={subscription} header="Subscription" />
            {subscription.category === 'free' && (
                showForm
                    ? (
                        <ChangeSubscriptionForm subscription={subscription}>
                            <Button showForm={false}>Cancel</Button>
                        </ChangeSubscriptionForm>
                    )
                    : (
                        <div className="actions">
                            <Button showForm={true}>Change Expiry</Button>
                            <DeleteSubscriptionForm subscription={subscription} />
                        </div>
                    )
            )}
        </>
    );
}

function ChangeSubscriptionForm({ subscription, children }) {

    const [onSubmit, error, isLoading] = useForm(
        'PATCH',
        `/admin/accounts/${subscription.account_id}/subscriptions/${subscription.id}`,
        useCallback(() => {
            window.location.reload();
        }, [])
    );

    const defaultValue = () => {
        const string = subscription.expires_at;
        if (typeof string === 'string' && string.length > 0) {
            const number = Date.parse(subscription.expires_at);
            if (typeof number === 'number' && Number.isNaN(number) === false) {
                const date = new Date(number);
                return date.toISOString().substring(0, 16);
            }
        }
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <ErrorMessage error={error} />
                <label htmlFor="subscription[expires_at]">Expires (optional)</label>
                <input type="datetime-local" name="subscription[expires_at]" placeholder="" defaultValue={defaultValue()} />
                <input disabled={isLoading} type="submit" value="Change Expiry" />
            </form>
            <div className="actions">
                <RemoveSubscriptionForm subscription={subscription} />
                {children}
            </div>
        </>
    );
}

function RemoveSubscriptionForm({ subscription }) {

    const [onSubmit, error, isLoading] = useForm(
        'PATCH',
        `/admin/accounts/${subscription.account_id}/subscriptions/${subscription.id}`,
        useCallback(() => {
            window.location.reload();
        }, []),
        true
    );

    return (
        <form className="button" onSubmit={onSubmit}>
            <ErrorMessage error={error} />
            <input type="hidden" name="subscription[expires_at]" value="" />
            <input className="destructive" disabled={isLoading} type="submit" value="Remove Expiry" />
        </form>
    );
}

function DeleteSubscriptionForm({ subscription }) {

    const [onSubmit, error, isLoading] = useForm(
        'DELETE',
        `/admin/accounts/${subscription.account_id}/subscriptions/${subscription.id}`,
        useCallback(() => {
            window.location.reload();
        }, []),
        true
    );

    return (
        <form className="button" onSubmit={onSubmit}>
            <ErrorMessage error={error} />
            <input className="destructive" disabled={isLoading} type="submit" value="Delete Subscription" />
        </form>
    );
}

function AddFreeSubscription() {

    const [showForm, setShowForm] = useState(false);

    const Button = ({ showForm, children }) => {
        return (
            <div className="actions">
                <button
                    onClick={(event) => {
                        event.preventDefault();
                        setShowForm(showForm);
                    }}
                >{children}</button>
            </div>
        );
    };

    return showForm
        ? (
            <>
                <AddSubscriptionForm />
                <Button showForm={false}>Cancel</Button>
            </>
        )
        : <Button showForm={true}>Add Free Subscription</Button>;
}

function AddSubscriptionForm() {

    const { account_id } = useParams();

    const [onSubmit, error, isLoading] = useForm(
        'POST',
        `/admin/accounts/${account_id}/subscriptions`,
        useCallback(() => {
            window.location.reload();
        }, [])
    );

    return (
        <form onSubmit={onSubmit}>
            <ErrorMessage error={error} />
            <label for="subscription[expires_at]">Expires (optional)</label>
            <input type="datetime-local" name="subscription[expires_at]" placeholder="" />
            <input disabled={isLoading} type="submit" value="Add Free Subscription" />
        </form>
    );
}
