import { useEffect, useState } from 'react';
import API from './API';

export default function useResource(url) {

    const [resource, setResource] = useState(undefined);
    const [error, setError] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true);
        API.request(url)
            .then(setResource)
            .catch(setError)
            .finally(() => setIsLoading(false));
        return () => {
            setResource(undefined);
            setError(undefined);
            setIsLoading(false);
        };
    }, [url]);

    return [resource, error, isLoading];
};
