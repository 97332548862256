import { useMemo } from 'react';

export default function useQuery(...names) {
    const query = window.location.search;
    return useMemo(() => {
        const parameters = new URLSearchParams(query);
        return names
            .map((name) => {
                return parameters.get(name);
            })
            .filter((value) => {
                return (typeof value === 'string' && value.length > 0);
            });
    }, [names, query]);
};
