import React from 'react';
import { APIValidationError } from '../Data/API';

export default function ErrorMessage({ error }) {
    if (error instanceof APIValidationError) {
        return (
            <div className="error-list">
                <h3>Error</h3>
                <ul>
                    {Object.entries(error.fields).map(([field, errors]) => errors.map(error => (
                        <li className="error">{field}: {error['error']}</li>
                    )))}
                </ul>
            </div>
        );
    }
    else if (error instanceof Error) {
        error = error.message;
    }
    if (typeof error === 'string' && error.length > 0) {
        return <p className="error">{error}</p>;
    }
    return null;
}
