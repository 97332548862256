import React, { useCallback } from 'react';
import { login } from '../Data/useAuthentication';
import useDocumentTitle from '../Data/useDocumentTitle';
import useForm from '../Data/useForm';
import ErrorMessage from './ErrorMessage';

export default function Login() {
    useDocumentTitle('Login');

    const [onSubmit, error, isLoading] = useForm(
        'POST',
        '/admin/authenticate',
        useCallback(({ authentication_token }) => {
            login(authentication_token);
        }, [])
    );

    return (
        <>
            <h2>Login</h2>
            <form onSubmit={onSubmit}>
                <ErrorMessage error={error} />
                <input type="text" placeholder="Email" name="account[email]" />
                <input type="password" placeholder="Password" name="account[password]" />
                <input disabled={isLoading} type="submit" value="Login" />
            </form>
        </>
    );
};
