import { useCallback, useState } from 'react';
import useEventListener from './useEventListener';

function dispatchCustomStorageEvent(storageArea, key, newValue) {
    window.dispatchEvent(
        new CustomEvent('custom-storage', {
            detail: { storageArea, key, newValue }
        })
    );
}

window.addEventListener('storage', (event) => {
    const { storageArea, key, newValue } = event;
    dispatchCustomStorageEvent(storageArea, key,
        (newValue === null) ? undefined : newValue
    );
});

Storage.prototype.getJSON = function (key) {
    const string = this.getItem(key);
    if (typeof string === 'string') {
        return JSON.parse(string);
    }
};

Storage.prototype.setJSON = function (key, value) {
    if (value === undefined) {
        this.removeItem(key);
    }
    else {
        const string = JSON.stringify(value);
        this.setItem(key, string);
    }
    dispatchCustomStorageEvent(this, key, value);
};

function useStorage(storageArea, key) {

    const get = useCallback(() => {
        return storageArea.getJSON(key);
    }, [storageArea, key]);

    const [value, setValue] = useState(get);

    const set = useCallback((value) => {
        storageArea.setJSON(key, value);
    }, [storageArea, key]);

    const remove = useCallback(() => {
        storageArea.setJSON(key, undefined);
    }, [storageArea, key]);

    useEventListener('custom-storage', useCallback((event) => {
        if (event.detail.storageArea === storageArea && event.detail.key === key) {
            setValue(event.detail.newValue);
        }
    }, [storageArea, key]));

    return [value, set, remove];
}

export function useLocalStorage(key) {
    return useStorage(window.localStorage, key);
};

export function useSessionStorage(key) {
    return useStorage(window.sessionStorage, key);
};
