import React from 'react';
import { Link } from 'react-router-dom';
import { logout } from '../Data/useAuthentication';
import useDocumentTitle from '../Data/useDocumentTitle';

export default function Home() {
    useDocumentTitle('Home');

    const onClick = (event) => {
        event.preventDefault();
        if (window.confirm('Logout?')) {
            logout();
        }
    };

    return (
        <>
            <h2>Home</h2>
            <div className="actions">
                <Link className="button" to="/accounts">Search Accounts</Link>
            </div>
            <div className="actions">
                <Link className="button" to="/account">My Account</Link>
                <button onClick={onClick}>Logout</button>
            </div>
        </>
    );
};
