import { useCallback, useState } from 'react';
import API from './API';
import Formatter from './Formatter';

export default function useForm(method, path, onSuccess, onError) {

    const [error, setError] = useState(undefined);
    const [isLoading, setIsLoading] = useState(false);

    const onSubmit = useCallback((event) => {
        event.preventDefault();
        const body = new FormData(event.target);
        setIsLoading(true);
        setError(undefined);
        API.request(path, { method, body })
            .then(onSuccess)
            .catch(error => {
                setError(error);
                if (typeof onError === 'function') {
                    onError(error);
                }
                else if (onError === true) {
                    const message = Formatter.errorAlert(error);
                    alert(message);
                }
            })
            .finally(() => setIsLoading(false));
    }, [method, path, onSuccess, onError]);

    return [onSubmit, error, isLoading];
};
